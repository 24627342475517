<template>
  <div>
    <h2 class="jsmb-title-detail has-text-text is-size-4">mes paiements / mes factures</h2>
    <div class="columns" style="display:none">
      <div class="column is-12 has-text-centered-mobile">
        <img src="https://dummyimage.com/600x800/000/fff" alt="" class="jsmb-facture">
        <button class="button is-primary">Envoyer la facture par email</button>
        <button class="button is-primary">Imprimer la facture</button>
      </div>
    </div>
    <div class="columns is-multiline">
      <div class="column is-12" v-for="(paiement,index) in paiements" :key="'paiement-'+index">
        <div class="columns is-multiline background-white is-vcentered  has-text-left">
          <div class="column is-6 ">
            <h3 class="has-text-primary is-size-4 has-text-weight-bold is-uppercase">Commande n°{{paiement.id}}</h3>
          </div>
          <div class="column has-text-right has-text-left-mobile is-6">
            <span class="tag is-info">{{paiement.etatPaiement}}</span>
          </div>
          <div class="column is-12  has-text-left-mobile">
            <h3>Du <strong>{{paiement.dateDebut}}</strong> au <strong>{{paiement.dateFin}}</strong></h3>
          </div>
          <div class="column is-12  has-text-left-mobile">
            <small>Prochain prélèvement <strong>{{paiement.dateDebut}}</strong></small>
          </div>

        </div>
        <div class="columns paiement-list is-multiline is-vcentered" v-for="(facture,index) in paiement.facture" :key="'facture-'+index">
          <div class="column">
            <div class="columns is-multiline fact-header">
              <div class="column is-6 ">
                <small><span class="has-text-weight-bold is-uppercase">Facture n°{{facture.factureNo}}</span></small>
              </div>
              <div class="column is-6  has-text-right has-text-left-mobile">
                <span class="tag">{{facture.etat}}</span>
              </div>
              <div class="column has-text-left has-text-left-left">
                <small>Du <strong>{{facture.dateDebut}}</strong> au <strong>{{facture.dateFin}}</strong></small>
              </div>
              <div class="column is-12">
                <small>Prix HT : {{facture.paiementMontant}} €</small>
              </div>
              <div class="column is-12">
                <small>Prix TVA : {{facture.paiementFDS}} €</small>
              </div>
              <div class="column is-12">
                <small>Prix TTC : {{facture.paiementTotal}} €</small>
              </div>

              <div class="column has-text-right">
                <button class="button is-primary is-small" @click="isCardModalActive = true">voir</button>
              </div>
            </div>
          </div>
          <div class="column is-12 no-padding fact-footer has-text-left">
            <div class="columns is-multiline">
              
            </div>
          </div>
          
          <div class="column has-text-right">
            <b-modal :active.sync="isCardModalActive" has-modal-card class="ovf">
              <div class="card " id="jsmbModal" ref="jsmbModal" style="width: 960px">

                  <div class="introFacture">
                    <div class="columns has-text-left is-multiline">
                      <div class="column is-12">
                        <h2 class="is-uppercase is-size-4 has-text-weight-bold has-text-text">facture client</h2>
                      </div>
                      <div class="column is-12">
                        <p>{{paiement.facture.nom}}</p>
                        <p>Code de confirmation : {{paiement.facture.codeConfirmation}}</p>
                        <p>{{paiement.facture.date}}</p>
                        <p>Facture #{{paiement.facturefactureNo}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="infoFacture">
                    <div class="columns has-text-left has-border-bottom has-border-top">
                      <div class="column is-6 has-text-dark is-uppercase">
                        BATEAU-SKIPPER
                      </div>
                      <div class="column is-6 has-text-right has-text-weight-bold">
                        {{paiement.facture.nomBSkipper}}
                      </div>
                    </div>

                    <div class="columns has-text-left has-border-bottom">
                      <div class="column is-6 has-text-dark is-uppercase">
                        Nom bateau-stockeur
                      </div>
                      <div class="column is-6 has-text-right has-text-weight-bold">
                        {{paiement.facture.nomBstockeur}}
                      </div>
                    </div>

                    <div class="columns has-text-left has-border-bottom">
                      <div class="column is-6 has-text-dark is-uppercase">
                        ADRESSE du bateau-stockeur
                      </div>
                      <div class="column is-6 has-text-right has-text-weight-bold">
                        {{paiement.facture.adresse}}
                      </div>
                    </div>

                    <div class="columns has-text-left has-border-bottom">
                      <div class="column is-6 has-text-dark is-uppercase">
                        type de facturation
                      </div>
                      <div class="column is-6 has-text-right has-text-weight-bold">
                        {{paiement.facture.typeFacturation}}
                      </div>

                    </div>
                  </div>
                  <div class="columns infoFacture has-text-left ">
                    <div class="column column is-8 is-offset-2">
                      <div class="columns is-multiline has-date">
                        <div class="column is-6">
                          <strong>Date arrivée</strong>
                          <p>{{paiement.facture.dateArrive}}</p>
                        </div>
                        <div class="column is-6">
                          <strong>Date retour</strong>
                          <p>{{paiement.facture.dateRetour}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br>
                  <div class="introFacture">
                    <div class="columns has-text-left is-multiline">
                      <div class="column is-12">
                        <h2 class="is-uppercase is-size-4 has-text-weight-bold has-text-text">paiement</h2>
                      </div>
                      <div class="column is-12" style="margin-bottom:4rem">
                        <p>Paiement reçu : {{paiement.facture.paiementRecu}}</p>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column is-8 background-white has-text-left">
                        Paiement reçu : {{paiement.facture.paiementRecu}}
                      </div>
                      <div class="column background-white has-text-right">
                        <strong>{{paiement.facture.paiementMontant}} €</strong>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column is-8 background-trans has-text-left">
                        JeStockeMonBateau<br>
                        Frais de service (TVA comprise)
                      </div>
                      <div class="column background-trans has-text-right">
                        <strong>{{paiement.facture.paiementFDS}} €</strong>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column is-8 background-white has-text-left">
                      </div>
                      <div class="column is-2 background-white has-text-right">
                        <strong>Total</strong>
                      </div>
                      <div class="column background-white has-text-right">
                        <strong>{{paiement.facture.paiementTotal}} €</strong>
                      </div>
                    </div>

                    <div class="columns has-margin-top">
                      <div class="column is-6 background-white has-text-left">
                        <small>Paiement {{paiement.facture.moyenPaiement+""+paiement.facture.cardNumber}}</small>
                      </div>
                      <div class="column background-white has-text-right">
                        <strong>{{paiement.facture.paiementTotal}} €</strong>
                      </div>
                    </div>

                  </div>
                  <div style="padding:1rem" class="has-text-centered">
                    <button class="button is-small is-primary" @click="download">Télécharger le PDF</button>
                  </div>
              </div>
            </b-modal>
          </div>
        </div>
        <div class="columns background-white is-vcentered  has-text-left last-row">
          <div class="column ">
            <h3 class="has-text-dark has-text-weight-bold is-uppercase">Coût Total HT : <strong>{{paiement.totalHT}}€</strong></h3>
          </div>
          <div class="column ">
            <p class="has-text-dark has-text-weight-bold is-uppercase has-text-left">Coût Total TVA : <strong>{{paiement.totalTVA}}€</strong></p>
          </div>
          <div class="column ">
            <p class="has-text-dark has-text-weight-bold is-uppercase has-text-left"> Coût Total TTC : <strong>{{paiement.totalTTC}}€</strong></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Payments',
  data(){
    return{
      isCardModalActive: false,
      paiements: [
        {
          id:0,
          etatPaiement:"en cours",
          dateDebut:"12/12/2012",
          dateFin:"12/12/2014",
          dateNextPaiement:"12/12/2018",
          totalHT:"20",
          totalTVA:"180",
          totalTTC:"100",
          facture:[
            {
              nom:"Mlle Bagnaud Marine",
              codeConfirmation:"MHTGOH8",
              etat:"en cours",
              factureNo:"123456",
              nomBSkipper:"Marine Bagnaud",
              nomBstockeur:"Brice",
              adresse:"Marine Lieu dit Pichauris, 13190 ALLAUCH",
              typeFacturation:"au mois",
              dateArrive:"mardi 10 janvier 2019",
              dateRetour:"mardi 10 janvier 2019",
              dateDebut:"12/12/2012",
              dateFin:"12/12/2014",
              paiementRecu:"mardi 10 janvier 2019",
              paiementMontant:"150",
              paiementFDS:"20",
              paiementTotal:"170",
              moyenPaiement:"Visa",
              cardNumber:"170************541",
          },
          {
              nom:"Mlle Bagnaud Marine",
              codeConfirmation:"MHTGOH8",
              etat:"en cours",
              factureNo:"123456",
              nomBSkipper:"Marine Bagnaud",
              nomBstockeur:"Brice",
              adresse:"Marine Lieu dit Pichauris, 13190 ALLAUCH",
              typeFacturation:"au mois",
              dateArrive:"mardi 10 janvier 2019",
              dateRetour:"mardi 10 janvier 2019",
              dateDebut:"12/12/2012",
              dateFin:"12/12/2014",
              paiementRecu:"mardi 10 janvier 2019",
              paiementMontant:"150",
              paiementFDS:"20",
              paiementTotal:"170",
              moyenPaiement:"Visa",
              cardNumber:"170************541",
          }
        ],
        },
        {
          id:1,
          etatPaiement:"en cours",
          dateDebut:"12/12/2012",
          dateFin:"12/12/2014",
          dateNextPaiment:"12/12/2018",
          facture:[
            {
              nom:"Mlle Bagnaud Marine",
              codeConfirmation:"MHTGOH8",
              etat:"en cours",
              factureNo:"123456",
              nomBSkipper:"Marine Bagnaud",
              nomBstockeur:"Brice",
              adresse:"Marine Lieu dit Pichauris, 13190 ALLAUCH",
              typeFacturation:"au mois",
              dateArrive:"mardi 10 janvier 2019",
              dateRetour:"mardi 10 janvier 2019",
              dateDebut:"12/12/2012",
              dateFin:"12/12/2014",
              paiementRecu:"mardi 10 janvier 2019",
              paiementMontant:"150",
              paiementFDS:"20",
              paiementTotal:"170",
              moyenPaiement:"Visa",
              cardNumber:"170************541",
          },
          {
              nom:"Mlle Bagnaud Marine",
              codeConfirmation:"MHTGOH8",
              etat:"en cours",
              factureNo:"123456",
              nomBSkipper:"Marine Bagnaud",
              nomBstockeur:"Brice",
              adresse:"Marine Lieu dit Pichauris, 13190 ALLAUCH",
              typeFacturation:"au mois",
              dateArrive:"mardi 10 janvier 2019",
              dateRetour:"mardi 10 janvier 2019",
              dateDebut:"12/12/2012",
              dateFin:"12/12/2014",
              paiementRecu:"mardi 10 janvier 2019",
              paiementMontant:"150",
              paiementFDS:"20",
              paiementTotal:"170",
              moyenPaiement:"Visa",
              cardNumber:"170************541",
          }
        ],
        },
        {
          id:2,
          etatPaiement:"en cours",
          dateDebut:"12/12/2012",
          dateFin:"12/12/2014",
          dateNextPaiment:"12/12/2018",
          facture:[
            {
              nom:"Mlle Bagnaud Marine",
              codeConfirmation:"MHTGOH8",
              etat:"en cours",
              factureNo:"123456",
              nomBSkipper:"Marine Bagnaud",
              nomBstockeur:"Brice",
              adresse:"Marine Lieu dit Pichauris, 13190 ALLAUCH",
              typeFacturation:"au mois",
              dateArrive:"mardi 10 janvier 2019",
              dateRetour:"mardi 10 janvier 2019",
              dateDebut:"12/12/2012",
              dateFin:"12/12/2014",
              paiementRecu:"mardi 10 janvier 2019",
              paiementMontant:"150",
              paiementFDS:"20",
              paiementTotal:"170",
              moyenPaiement:"Visa",
              cardNumber:"170************541",
          },
          {
              nom:"Mlle Bagnaud Marine",
              codeConfirmation:"MHTGOH8",
              etat:"en cours",
              factureNo:"123456",
              nomBSkipper:"Marine Bagnaud",
              nomBstockeur:"Brice",
              adresse:"Marine Lieu dit Pichauris, 13190 ALLAUCH",
              typeFacturation:"au mois",
              dateArrive:"mardi 10 janvier 2019",
              dateRetour:"mardi 10 janvier 2019",
              dateDebut:"12/12/2012",
              dateFin:"12/12/2014",
              paiementRecu:"mardi 10 janvier 2019",
              paiementMontant:"150",
              paiementFDS:"20",
              paiementTotal:"170",
              moyenPaiement:"Visa",
              cardNumber:"170************541",
          }
        ],
        },
        {
          id:3,
          etatPaiement:"en cours",
          dateDebut:"12/12/2012",
          dateFin:"12/12/2014",
          dateNextPaiment:"12/12/2018",
          facture:[
            {
              nom:"Mlle Bagnaud Marine",
              codeConfirmation:"MHTGOH8",
              etat:"en cours",
              factureNo:"123456",
              nomBSkipper:"Marine Bagnaud",
              nomBstockeur:"Brice",
              adresse:"Marine Lieu dit Pichauris, 13190 ALLAUCH",
              typeFacturation:"au mois",
              dateArrive:"mardi 10 janvier 2019",
              dateRetour:"mardi 10 janvier 2019",
              dateDebut:"12/12/2012",
              dateFin:"12/12/2014",
              paiementRecu:"mardi 10 janvier 2019",
              paiementMontant:"150",
              paiementFDS:"20",
              paiementTotal:"170",
              moyenPaiement:"Visa",
              cardNumber:"170************541",
          },
          {
              nom:"Mlle Bagnaud Marine",
              codeConfirmation:"MHTGOH8",
              etat:"en cours",
              factureNo:"123456",
              nomBSkipper:"Marine Bagnaud",
              nomBstockeur:"Brice",
              adresse:"Marine Lieu dit Pichauris, 13190 ALLAUCH",
              typeFacturation:"au mois",
              dateArrive:"mardi 10 janvier 2019",
              dateRetour:"mardi 10 janvier 2019",
              dateDebut:"12/12/2012",
              dateFin:"12/12/2014",
              paiementRecu:"mardi 10 janvier 2019",
              paiementMontant:"150",
              paiementFDS:"20",
              paiementTotal:"170",
              moyenPaiement:"Visa",
              cardNumber:"170************541",
          }
        ],
        },
      ],
    }
  },
  methods: {
   download () {
   },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .jsmb-facture {
    margin-bottom: 1rem;
  }
  .button {
    margin-right:2rem;
    margin-bottom: 2rem;
  }
  .background-white {
    padding:1rem;
    background: #fff;
  }
  .background-trans {
    padding:1rem;
  }
  .paiement-list {
    padding: 0;
    border: 1px solid #eee;
    margin:1rem 0;
    background: #fff;
  }

  .fact-footer {
    padding:0.2rem 1rem;
  }

  .paiement-list button {
    margin: 0;
  }
  .fact-header{
    padding:0.5rem 1rem;
  }

  .introFacture {
    background: #dcdddf;
    padding: 2rem;
  }
  .infoFacture {
    background: #f3f3f3;
    padding: 2rem;
  }
  .has-border-top {
    border-top: 2px solid #000;
  }
  .has-border-bottom {
    border-bottom: 2px solid #bfbfbf;
    padding:1rem 0
  }
  .has-date {
    padding: 2rem;
    background: #fff;
  }
  .has-date strong {
    margin-bottom: 1rem;
    display: block;
  }
  .last-row {
    margin-bottom: 6rem;
  }
  .tag {
    border-radius: 4px;
  }
</style>
<style>
  .ovf .animation-content {
    overflow: scroll
  }
</style>
